@import url("./services/css-variable.scss");

$color-primary: #0c1d35 !important;

.ant-layout-header {
  background: $color-primary;
}
.h-100 {
  height: 100% !important;
}

.option {
  &:active {
    border-bottom: 1px solid;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #000 !important;
}

.border-b-2 {
  transition: 500ms;
  border-bottom: 2px solid;
}

.min-height {
  min-height: 50rem;
}

.ant-select-selection-item {
  text-transform: capitalize;
}

.ant-select-selection-item {
  text-transform: capitalize;
}
.justify-end {
  justify-content: end;
}

.mr-4-5 {
  margin-right: 4.5rem;
}

.ml-4 {
  margin-left: 1.75rem;
}

.submit-btn {
  color: white !important;
  &:hover {
    color: white !important;
  }
  &:active {
    color: white !important;
    &:hover {
      color: white !important;
    }
  }
  &:focus {
    color: white !important;
    &:hover {
      color: white !important;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $color-primary;
}
p {
  margin-top: 0;
  margin-bottom: 0em !important;
}

.px-14 {
  padding: 0 3.5rem;
}

.pr-44 {
  padding: 0 11rem 0rem 0rem;
}

.file-loader {
  /* background:salmon; */
  border-radius: 8px;
  padding: 50px 0;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .circular-progress {
    position: relative;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background: conic-gradient(#000 3.6deg, #fff 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circular-progress::before {
    content: "";
    position: absolute;
    height: 210px;
    width: 210px;
    border-radius: 50%;
    background-color: #f0f2f5;
  }

  .progress-value {
    position: relative;
  }

  .precentText {
    font-size: 1rem;
    font-weight: 600;
  }
}
.dot-plash {
  width: 1rem;
  padding: 0.1rem 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: #000;
  box-shadow: 32px 0 #000, -32px 0 #000;
  position: relative;
  animation: flash 1s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #000;
    box-shadow: 32px 0 #000, -32px 0 #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 32px 0 #000, -32px 0 #000;
  }
  100% {
    background-color: #000;
    box-shadow: 32px 0 #fff, -32px 0 #000;
  }
}

::-webkit-scrollbar {
  // width: 0.2rem;
  height: 0.4rem;
  color: black !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary;
}

.hover-text-white {
  &:hover {
    color: white !important;
    border-color: white !important;
  }
}

.text-italic {
  font-style: italic;
}

.text-xs {
  font-size: 9px;
}
